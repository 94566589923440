.foundation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 30px;
    width: 1200px;
    margin-bottom: 150px;
    background-color: var(--orange);
    border-radius: 10px;
    position: relative;
}

.foundation p {
    margin-bottom: 20px;
}

.f-info {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
    width: 100%;
    background-color: var(--bg);
    border-radius: 5px;
}

.fi-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-right: 30px;
}

.fi-col p {
    margin-bottom: 10px;
}

.fi-col p:nth-child(2) {
    margin-bottom: 0;
}

.f-close-btn {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    background-color: transparent;
    border-radius: 5px;
}

.f-close-btn:hover {
    background-color: var(--orange);
}

.f-close-btn::after {
    pointer-events: none;
    position: absolute;
    top: 3px;
    left: 9px;
    content: '';
    width: 2px;
    height: 14px;
    background-color: var(--g10);
    transform: rotate(-45deg);
    border-radius: 2px;
}

.f-close-btn::before {
    pointer-events: none;
    position: absolute;
    top:3px;
    right: 9px;
    content: '';
    width: 2px;
    height: 14px;
    background-color: var(--g10);
    transform: rotate(45deg);
    border-radius: 2px;
}