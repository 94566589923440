.contact {
    width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 50px;
}

.c-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
}

.ct-container {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: flex-start;
}

.ctc-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    /* margin-top: 10px; */
    margin-left: 40px;
}

.ct-icon {
    position: relative;
}

.ct-icon img {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.ct-icon-info {
    opacity: 1;
    position: absolute;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
    background-color: var(--orange);
    border-radius: 3px;
    padding: 5px 15px 5px 15px;
    transition: all .2s ease-in-out;
    pointer-events: none;
}

.ct-icon-info img {
    position: absolute;
    top: -13px;
    left: 50%;
    transform: translateX(-50%);
    width: 30px;
    height: 30px;
    z-index: -1;
}

.cti-link-circ {
    z-index: -1;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 2px);
    left: calc(50% - 1px);
    transform: translate(-50%, -50%);
    background-color: var(--orange);
}

.c-content {
    width: 100%;
    height: 441px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.cc-form {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.ccf-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
}

.ccf-input {
    outline: none;
    border: 1px solid var(--gA0);
    padding: 10px 0 10px 10px;
    width: calc(50% - 10px);
    border-radius: 3px;
    background-color: transparent;
    font-family: 'Poppins Light';
    font-size: 14px;
    color: var(--g10);
    line-height: 125%;
    opacity: 1;
}

#nameInput {
    width: 100%;
}

.ccf-input:focus,
.ccf-textarea:focus {
    border: 1px solid var(--g10);
}

.ccf-input::placeholder,
.ccf-textarea::placeholder {
    font-family: 'Poppins Light';
    font-size: 14px;
    color: var(--gA0);
    line-height: 125%;
    opacity: 1;
}

.ccf-textarea {
    outline: none;
    border: 1px solid var(--gA0);
    padding: 10px 0 10px 10px;
    width: 100%;
    resize: none;
    height: 250px;
    border-radius: 3px;
    background-color: transparent;
    font-family: 'Poppins Light';
    font-size: 14px;
    color: var(--g10);
    line-height: 125%;
    opacity: 1;
}

.ccf-button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 30px;
    text-decoration: none;
}

.ccf-button p {
    margin-right: 20px;
}

#ccf-arrow {
    height: 35px;
    width: 35px;
}

.ccf-arrow-circ {
    z-index: -1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: var(--orange);
}

.cc-map {
    background-color: var(--gD0);
    width: calc(40% - 20px);
    height: 100%;
    border-radius: 10px;
}

.copyright {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 100px;
}

.copyright div {
    display: flex;
    align-items: center;
}

.copyright div a {
    margin-left: 10px;
    color: var(--g10);
    text-decoration: underline;
}