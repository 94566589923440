.workers {
    width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 150px;
}

.w-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
}

.wt-text {
    position: relative;
}

.orange-circ {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: var(--orange);
    position: absolute;
    top: -5px;
    left: calc(50% + 5px);
    z-index: -1;
}

.blue-circ {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    background-color: var(--blue);
    position: absolute;
    top: -20px;
    left: calc(50% - 40px);
    z-index: -1;
}

.g-selector {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.w-cards {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.w-cards .f20 {
    text-align: center;
}

.w-cards-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 20px 0 20px 0;
}

.w-card {
    width: 200px;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    margin-right: 20px;
    /* background-color: var(--gD0); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.w-card img {
    width: 100%;
}

.w-card-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: calc(100% - 40px);
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.divider-dot {
    width: 5px;
    height: 5px;
    background-color: var(--g10);
    border-radius: 50%;
    margin: 10px 0 10px 0;
}

.w-card span {
    pointer-events: none;
    position: absolute;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: var(--blue);
}

#group-0-cards span {
    background-color: var(--gOrange) !important;
}

#group-1-cards span {
    background-color: var(--gYellow) !important;
}

#group-2-cards span {
    background-color: var(--gGreen) !important;
}

#group-3-cards span {
    background-color: var(--gRed) !important;
}

#group-4-cards span {
    background-color: var(--gBlue) !important;
}

#group-5-cards span {
    background-color: var(--gYellow) !important;
}

.workerrow-container {
    display: none;
}

.show {
    display: flex !important;
}

.group-navlink-container {
    background: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    outline: none;
}
