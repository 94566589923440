.titlescreen {
    width: 1200px;
    height: 100vh !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ts-title img {
    width: 90px;
}

.ts-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
}

.ts-title .f16 {
    margin-top: 50px;
}

.f72 {
    text-align: center;
    width: 10ch;
    pointer-events: none;
}

.news {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
}

.contact .news {
    align-items: flex-start;
    margin-bottom: 20px;
}

.news .f21 {
    color: red !important;
    text-align: center !important;
}

.ts-arrow {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.ts-arrow .f24 {
    margin-bottom: 20px;
}

#arrow {
    height: 45px;
}

.arrow-circ {
    z-index: -1;
    position: absolute;
    top: calc(50% - 10px);
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: var(--orange);
}