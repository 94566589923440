.navbar {
    position: fixed;
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0 20px 0;
    background-color: var(--bg);
    z-index: 100;
}

.nb-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}

.nb-logo {
    width: 40px;
    height: 40px;
    background-image: url('../assets/icons/njo_logo.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 20px;
}

.nb-links {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.nb-link {
    position: relative;
    margin-left: 20px !important;
    cursor: pointer;
}

.nb-link-nomargin {
    position: relative;
    margin-bottom: 20px;
    cursor: pointer;
}

.nb-links .nb-link:nth-child(1) {
    margin-left: 0;
}

.nb-link-circ {
    z-index: -1;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--orange);
}
