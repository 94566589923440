.dashboard {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.dashboard-menu {
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 20px 20px 20px 20px;
    z-index: 200;
    box-shadow: 0 10px 10px 0 rgba(0,0,0,.15);
}

.dashboard-menu::after {
    position: absolute;
    bottom: 0px;
    left: 0;
    content: '';
    width: calc(100%);
    height: 1px;
    background-color: var(--gD0);
}

.dm-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}

.menu-btn {
    font-family: 'Poppins Regular' !important;
    font-size: 16px;
    line-height: 125%;
    outline: none;
    text-decoration: none;
    background: none;
    border: none;
    position: relative;
    color: var(--g10);
    cursor: pointer;
}

.menu-btn p {
    pointer-events: none;
}

.menu-btn::after {
    position: absolute;
    bottom: -5px;
    left: 0;
    content: '';
    width: 0%;
    height: 2px;
    background-color: var(--g10);
    z-index: 100;
    transition: all .2s ease-in-out;
}

.menu-btn:hover::after {
    width: 100%;
}

.menu-btn.active::after {
    width: 100%;
}

/* Dashworkers */
.dashgroups {
    width: 100%;
    max-width: 1200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 20px;
    margin-top: 60px;
}

.dw-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
}

.dwg-bar {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--gD0);
    padding: 0 20px;
}

.group-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}

.group-color {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.group-actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}

.ga-add {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
}

.ga-add p {
    font-size: 14px;
    color: var(--gA0);
    cursor: pointer;
}

.ga-add p:hover {
    color: var(--g10);
}

.ga-open {
    transform: rotate(-90deg);
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.dw-group.active .ga-open {
    transform: rotate(0deg);
}

.dwg-workers {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 50px;
}

.dwg-worker {
    position: relative;
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.dwgwi {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 10px;
    background-color: var(--gD0);
    object-fit:cover;
}

.dwgw-name {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
}

.dwgw-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0px;
}

.dwg-hover-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 250px;
}

.dwg-hover-container:hover .img-container {
    opacity: 1;
    transition: all .1s ease-in-out;
}

.img-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 250px;
    backdrop-filter: blur(5px);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
}

.img-container .delete-icon {
    width: 30px;
    height: 30px;
    border-radius: 0;
    background-color: transparent;
    cursor: pointer;
}

.content-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.dwg-imgs {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 50px;
}

.dwg-image {
    position: relative;
    width: calc(30% - 13.33px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.dwg-image img {
    width: 100%;
    height: 200px;
    border: none;
    border-radius: 10px;
    background-color: var(--gD0);
}

.dwgii {
    object-fit:cover;
}

.dwg-image:hover .dwgi-name {
    opacity: 1;
    transition: all .1s ease-in-out;
}

.dwgi-name {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    opacity: 0;
}

.dwgi-name img {
    width: 30px;
    height: 30px;
    border-radius: 0;
    background-color: transparent;
    cursor: pointer;
}

/* Upload popup */
.upload-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 340px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 20px 0 rgba(0,0,0,.25), 0 5px 5px 0 rgba(0,0,0,.2);
    padding: 20px;
    z-index: 100;
}

.upload-container p{
    margin-top: 10px;
}

.upload-popup input {
    font-family: 'Poppins Light' !important;
    font-size: 14px;
    color: var(--g10);
    line-height: 125%;
    outline: none;
    text-decoration: none;
    background: none;
    width: 300px;
    height: 40px;
    margin-top: 10px;
}

.textinput {
    padding: 0 10px;
    border: 1px solid var(--gD0);
}

.textinput:focus {
    border: 1px solid var(--g10);
}

.popup-btns {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

.popup-btns p {
    margin: 0;
}

.popup-btn {
    width: calc(50% - 5px);
    height: 40px;
    background-color: var(--g10);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: 0;
    border: 0;
}

.popup-btn p {
    color: white;
}

.popup-btn.del {
    background-color: var(--gRed);
}

.delete-icon {
    background-color: white !important;
    border-radius: 6px !important;
}

.delete-icon:hover {
    background-color: var(--gRed) !important;
    border-radius: 6px !important;
}

/* OTHER ACTIONS AT TOP OF PAGE */
.dg-actions {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    /* border-bottom: 1px solid var(--gD0); */
    padding: 20px 0;
    gap: 20px;
    border-bottom: 1px solid var(--gD0);
}

.dga-button {
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: all .1s ease-in-out;
    border: 1px solid var(--gA0);
}

.dga-button:hover {
    background-color: var(--g10);
    border: 1px solid var(--g10);
}

.dga-button p {
    transition: all .1s ease-in-out;
}

.dga-button:hover p {
    color: white !important;
}

.checkboxinput {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 15px;
    border-top: 1px solid var(--g10);
}

.checkboxinput input {
    width: 20px;
    height: 20px;
    margin-top: 0;
    margin-right: 10px;
}

.checkboxinput .f14 {
    margin: 0;
}

.fileinput {
    height: 26px !important;
}

.signup.fileinput {
    margin-bottom: 15px;
}

textarea.textinput {
    outline: none !important;
    width: 100% !important;
    height: 100px !important;
    resize: none;
    margin-top: 10px;
    padding-top: 10px;

    font-family: 'Poppins Light' !important;
    font-size: 14px;
    color: var(--g10);
    line-height: 125%;
}

.news-cards {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    column-gap: 20px;
    row-gap: 20px;
    flex-wrap: wrap;
}

.news-card {
    width: 200px;
    max-height: 200px;
    border: 1px solid var(--gA0);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    cursor: pointer;
}

.news-card:hover {
    border: 1px solid var(--g10);
}

.news-card:hover .news-card-title {
    border-bottom: 1px solid var(--g10);
}

.news-card-title {
    width: 100%;
    height: 60px;
    padding: 10px;
    border-bottom: 1px solid var(--gA0);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.news-card-title p:nth-child(2) {
    color: var(--gA0);
}

.news-card-content {
    padding: 10px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.news-card-content p {
    display: -webkit-box;
    max-width: 100%;
    /* text-overflow: ellipsis; */
    overflow: hidden;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
}

.dash-news {
    width: 100%;
    max-width: 1200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 20px;
    margin-top: 60px;
}