.news-section {
    width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 150px;
    gap: 20px;
}

.news-section .f21 {
    max-width: 350px;
    text-align: center;
    line-height: 115%;
}

.news {
    width: 1200px;
}

.news-cards {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    column-gap: 20px;
    row-gap: 20px;
    flex-wrap: wrap;
    margin-top: 20px;
}

.news-card {
    width: 200px;
    max-height: 200px;
    border: 1px solid var(--gA0);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    cursor: pointer;
}

.news-card:hover {
    border: 1px solid var(--g10);
}

.news-card:hover .news-card-title {
    border-bottom: 1px solid var(--g10);
}

.news-card-title {
    width: 100%;
    height: 60px;
    padding: 10px;
    border-bottom: 1px solid var(--gA0);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.news-card-title p:nth-child(2) {
    color: var(--gA0);
}

.news-card-content {
    padding: 10px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.news-card-content p {
    display: -webkit-box;
    max-width: 100%;
    /* text-overflow: ellipsis; */
    overflow: hidden;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
}

.ln-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 50%;
    width: 50%;
    max-width: 1000px;
    min-width: 340px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 20px 0 rgba(0,0,0,.25), 0 5px 5px 0 rgba(0,0,0,.2);
    padding: 20px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-y: scroll;
}

.ln-popup-title {
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--gA0);
}

.ln-popup-content {
    width: 100%;
    margin-bottom: 20px;
}

.ln-popup-btn .nb-link {
    margin: 0px !important;
}