.about {
    width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 150px;
}

.a-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
}

.at-text {
    position: relative;
}

.orange-circ {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: var(--orange);
    position: absolute;
    top: -5px;
    left: calc(50% + 5px);
    z-index: -1;
}

.blue-circ {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    background-color: var(--blue);
    position: absolute;
    top: -20px;
    left: calc(50% - 40px);
    z-index: -1;
}

.a-content {
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.a-content .f16:nth-child(2) {
    margin: 20px 0 20px 0;
}

.ac-text {
    width: 55%;
}

.ac-video {
    width: 450px;
    border-radius: 10px;
}

.a-cards {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ac-card-container {
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: none;
    outline: none;
    width: calc(33% - 20px);
}

.ac-card {
    position: relative;
    cursor: pointer;
    width: 100%;
    height: 175px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: none;
    transition: all .25s ease-in-out;
    overflow: hidden;
}

.ac-card.active {
    background-color: var(--orange);
}

.ac-card img {
    height: 60px;
    margin-bottom: 20px;
    pointer-events: none;
    z-index: 2;
}

.ac-card p {
    pointer-events: none;
    z-index: 2;
}

.ac-card span {
    pointer-events: none;
    position: absolute;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: var(--orange);
}

.a-section-container {
    width: 100%;
    padding: 30px;
    margin-top: 30px;
    position: relative;
}

.a-section {
    width: 100%;
}

.a-section p:nth-child(1),
.a-section p:nth-child(2) {
    margin-bottom: 20px;
}

.a-section-text p:nth-child(1),
.a-section-text p:nth-child(2) {
    margin: 0;
}