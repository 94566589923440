.groups {
    width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 150px;
}

.g-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
}

.gt-text {
    position: relative;
}

.orange-circ {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: var(--orange);
    position: absolute;
    top: -5px;
    left: calc(50% + 5px);
    z-index: -1;
}

.blue-circ {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    background-color: var(--blue);
    position: absolute;
    top: -20px;
    left: calc(50% - 40px);
    z-index: -1;
}

.g-content {
    margin-bottom: 50px;
}

.g-content .f16:nth-child(2) {
    margin: 20px 0 20px 0;
}

.g-cards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.group-card-container {
    border: none;
    background-color: transparent;
    width: calc(20% - 20px);
}

.gc-card {
    padding: 20px;
    position: relative;
    cursor: pointer;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--bg);
    border-radius: 10px;
    box-shadow: none;
    transition: all .25s ease-in-out;
    overflow: hidden;
}

/* #g-card-0 span {
    background-color: var(--gOrange);
}

#g-card-1 span {
    background-color: var(--gYellow);
}

#g-card-2 span {
    background-color: var(--gGreen);
}

#g-card-3 span {
    background-color: var(--gRed);
}

#g-card-4 span {
    background-color: var(--gBlue);
}

#g-card-5 span {
    background-color: var(--gYellow);
}

#g-card-0.active {
    background-color: var(--gOrange);
}

#g-card-1.active {
    background-color: var(--gYellow);
}

#g-card-2.active {
    background-color: var(--gGreen);
}

#g-card-3.active {
    background-color: var(--gRed);
}

#g-card-4.active {
    background-color: var(--gBlue);
}

#g-card-5.active {
    background-color: var(--gYellow);
} */

.gc-card img {
    height: 60px;
    margin-bottom: 20px;
    pointer-events: none;
    z-index: 2;
}

.gc-card p {
    pointer-events: none;
    z-index: 2;
    text-align: center;
}

.gc-card span {
    pointer-events: none;
    position: absolute;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: var(--orange);
}

.group-imgs-row {
    display: none;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.group-imgs-row div {
    height: 340px;
    width: calc(50% - 10px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--gD0);
    border-radius: 5px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.show {
    display: flex !important;
}
