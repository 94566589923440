/* Cairo */
@font-face {
    font-family: 'Cairo ExtraLight';
    src: url("../assets/fonts/Cairo-ExtraLight.ttf");
    font-weight: 200;
}

@font-face {
    font-family: 'Cairo Light';
    src: url("../assets/fonts/Cairo-Light.ttf");
    font-weight: 300;
}

@font-face {
    font-family: 'Cairo Regular';
    src: url("../assets/fonts/Cairo-Regular.ttf");
    font-weight: 400;
}

@font-face {
    font-family: 'Cairo Medium';
    src: url("../assets/fonts/Cairo-Medium.ttf");
    font-weight: 500;
}

@font-face {
    font-family: 'Cairo SemiBold';
    src: url("../assets/fonts/Cairo-SemiBold.ttf");
    font-weight: 600;
}

@font-face {
    font-family: 'Cairo Bold';
    src: url("../assets/fonts/Cairo-Bold.ttf");
    font-weight: 700;
}

@font-face {
    font-family: 'Cairo ExtraBold';
    src: url("../assets/fonts/Cairo-ExtraBold.ttf");
    font-weight: 800;
}

@font-face {
    font-family: 'Cairo Black';
    src: url("../assets/fonts/Cairo-Black.ttf");
    font-weight: 900;
}

/* Share Tech Mono */
@font-face {
    font-family: 'Share Tech Mono';
    src: url("../assets/fonts/ShareTechMono-Regular.ttf");
    font-weight: 400;
}

/* POPPINS */
@font-face {
  font-family: 'Poppins Thin';
  font-style: normal;
  src: url(../assets/fonts/Poppins-Thin.ttf);
  font-weight: 100;
}
  
@font-face {
  font-family: 'Poppins ExtraLight';
  font-style: normal;
  src: url(../assets/fonts/Poppins-ExtraLight.ttf);
  font-weight: 200;
}
  
@font-face {
  font-family: 'Poppins Light';
  font-style: normal;
  src: url(../assets/fonts/Poppins-Light.ttf);
  font-weight: 300;
}
  
@font-face {
  font-family: 'Poppins Regular';
  font-style: normal;
  src: url(../assets/fonts/Poppins-Regular.ttf);
  font-weight: 400;
}
  
@font-face {
  font-family: 'Poppins Medium';
  font-style: normal;
  src: url(../assets/fonts/Poppins-Medium.ttf);
  font-weight: 600;
}
  
@font-face {
  font-family: 'Poppins SemiBold';
  font-style: normal;
  src: url(../assets/fonts/Poppins-SemiBold.ttf);
  font-weight: 600;
}
  
@font-face {
  font-family: 'Poppins Bold';
  font-style: normal;
  src: url(../assets/fonts/Poppins-Bold.ttf);
  font-weight: 700;
}
  
@font-face {
  font-family: 'Poppins Black';
  font-style: normal;
  src: url(../assets/fonts/Poppins-Black.ttf);
  font-weight: 800;
}