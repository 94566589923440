.login {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title {
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 40px;
}

.title .f16-p {
    width: 300px;
}

.login-form {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.login-form input,
.login-form button {
    width: 400px;
    height: 30px;
    font-family: 'Poppins Light' !important;
    font-size: 14px;
    color: var(--g10);
    line-height: 125%;
    outline: none;
    text-decoration: none;
    background: none;
}

.login-form input {
    border: 1px solid var(--gA0);
    padding-left: 10px;
}

.login-form input:focus {
    border: 1px solid var(--g10);
}

.login-form button {
    border: none;
    background-color: var(--g10);
    color: white;
}