* {
    /* Style settings */
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    /* cursor: none !important; */

    /* Colors */
    --g10: #101010;
    --gA0: #A0A0A0;
    --gD0: #D0D0D0;
    --gFF: #FFFFFF;

    --blue: #8ACEFF;
    --orange: #FFD568;

    --gOrange: #ffc634;
    --gYellow: #fff640;
    --gGreen: #49e360;
    --gRed: #e65343;
    --gBlue: #56B0F1;

    --bg: #FFF7EC;
}

body {
    background-color: var(--bg);
}

.desktop {
    display: flex;
}

.mobile {
    display: none !important;
}

/* Fonts */
.f14 {
    font-family: 'Poppins Light' !important;
    font-size: 14px;
    color: var(--g10);
    line-height: 125%;
}

.f16 {
    font-family: 'Cairo Regular' !important;
    font-size: 16px;
    color: var(--g10);
    line-height: 125%;
}

.f16-p {
    font-family: 'Poppins Light' !important;
    font-size: 16px;
    color: var(--g10);
    line-height: 125%;
}

.f16-p-red {
    font-family: 'Poppins Light' !important;
    font-size: 16px;
    color: var(--gRed);
    line-height: 125%;
}

.f21 {
    font-family: 'Cairo Regular' !important;
    font-size: 20px;
    color: var(--g10);
    line-height: 100%;
}

.f24 {
    font-family: 'Cairo Regular' !important;
    font-size: 24px;
    color: var(--g10);
    line-height: 100%;
}

h5.f24 {
    font-family: 'Cairo Regular' !important;
    font-size: 20px !important;
}

.f24-p {
    font-family: 'Poppins Regular' !important;
    font-size: 24px;
    color: var(--g10);
    line-height: 100%;
}

.f36 {
    font-family: 'Cairo Medium' !important;
    font-size: 36px;
    color: var(--g10);
    line-height: 100%;
}

.f72 {
    font-family: 'Cairo ExtraLight' !important;
    font-size: 58px;
    color: var(--g10);
    line-height: 100%;
}

.f48-mono {
    font-family: 'Share Tech Mono' !important;
    font-size: 48px;
    color: var(--gD0);
    line-height: 100%;
}

.f16::selection,
.f20::selection,
.f24::selection,
.f100::selection {
    background-color: rgba(138, 206, 255, .5);
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.my-div-icon {
    width: 50px;
    height: 50px;
    border: 10px solid var(--gRed);
    border-radius: 50%;
    box-shadow: 5px 5px 10px 0px rgba(0,0,0,.4);
}